import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col} from 'reactstrap'
import { MDXRenderer } from "gatsby-plugin-mdx"
import Masonry from "react-masonry-component"

import {FaTwitter, FaInstagramSquare, FaLaptop, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MastHead from "../components/masthead"
import { SiteHeading, H1, SubHeading } from "../components/site-heading"
import { PostCard, PostTitle, PostSubTitle, PostMeta } from "../components/post-card"

const TeamPage = () => {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      allMdx(limit: 1000, filter: {fields: {slug: {regex: "/^/trillers//"}}}, sort: {fields: frontmatter___name, order: ASC}) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            name
            twitter
            linkedin
            instagram
            website
            avatar {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          body
        }
      }
      banner: file(relativePath: {eq: "images/artoo-team.jpg"}, sourceInstanceName: {eq: "trillers"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    
  `)  

  const members = data.allMdx.nodes
  const banner = data.banner.childImageSharp.fluid

  return (
    <Layout color="#f4f4f4">
      <SEO title="Team" />
      <MastHead imageData={banner} overlay>
        <SiteHeading>
          <H1>Trillers for Life</H1>
          <SubHeading></SubHeading>
        </SiteHeading>          
      </MastHead>
      <Container className="pt-5">                    
        <Row>   
          <Col className="mx-auto">                               
            <Masonry>
              {members.map((member, index) => {        
                  return (
                    <PostCard key={index}>
                      <figure className="rounded p-3 bg-white shadow-sm position-relative">
                        {member.frontmatter.avatar && 
                          <Img fluid={member.frontmatter.avatar.childImageSharp.fluid} />
                        }   
                        <figcaption className="p-4 card-img-bottom">                                              
                          <PostTitle className="text-center">
                          {member.frontmatter.name}
                          </PostTitle>
                          <PostSubTitle className="text-center">
                            {member.frontmatter.twitter &&
                              <a className="mx-auto" href={"https://twitter.com/" + member.frontmatter.twitter} target="_blank" rel="noopener norefferer"><FaTwitter>{member.frontmatter.twitter}</FaTwitter></a>
                            }
                            {member.frontmatter.linkedin &&
                              <a className="mx-auto" href={"https://linkedin.com/" + member.frontmatter.linkedin} target="_blank" rel="noopener norefferer"><FaLinkedin>{member.frontmatter.linkedin}</FaLinkedin></a>
                            } 
                            {member.frontmatter.instagram &&
                              <a className="mx-auto" href={"https://instagram.com/" + member.frontmatter.instagram} target="_blank" rel="noopener norefferer"><FaInstagramSquare>{member.frontmatter.instagram}</FaInstagramSquare></a>
                            } 
                            {member.frontmatter.website &&
                              <a className="mx-auto" href={member.frontmatter.website} target="_blank" rel="noopener norefferer"><FaLaptop>{member.frontmatter.website}</FaLaptop></a>
                            }                              
                          </PostSubTitle>                       
                          <PostMeta>
                            <MDXRenderer>
                              {member.body}
                            </MDXRenderer>
                          </PostMeta>
                        </figcaption>                 
                      </figure>
                    </PostCard>
                  )
                })}
            </Masonry>
          </Col>
        </Row>  
        </Container>  
    </Layout>
  )
}

export default TeamPage
