import {Link} from "gatsby"
import styled from "styled-components"

const PostCard = styled.div`
  // margin-bottom: 30px;

  @media screen and (min-width: 0px) {
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding-left: 15px;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  @media screen and (min-width: 1100px) {
    width: 33%;
    padding-left: 40px;
  }
`

const PostCardBig = styled.div`
  // margin-bottom: 30px;

  @media screen and (min-width: 0px) {
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding-left: 15px;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  @media screen and (min-width: 1100px) {
    width: 50%;
    padding-left: 10px;
  }
`

const PostTitle = styled.h2`
  font-size: 30px;
  // margin-top: 30px;
  // margin-bottom: 10px;
`

const PostSubTitle = styled.h3`
  font-weight: 300;
  margin: 0 0 10px;
  @include serif-font;
  font-family: 'Cormorant', 'Times New Roman', serif;
`

const PostLink = styled(Link)`
  color: ${props => props.theme.gray900};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.primary};
  }
`

const PostLinkExternal = styled.a`
  color: ${props => props.theme.gray900};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.primary};
  }
`
const PostMeta = styled.span`
  line-height: 1;
  font-size: 18px;
  font-style: italic;
  margin-top: 0;
  margin: 0px 0px;
  color: ${props => props.theme.gray600};
`
export {
    PostCard,
    PostCardBig,
    PostTitle,
    PostSubTitle,
    PostLink,
    PostLinkExternal,
    PostMeta
}